//import logo from './logo.svg';
//import './App.css';

const user = {
  firstName: 'Jaroslav',
  lastName: 'Plachý',
};

function formatName(user) {
  return user.firstName + ' ' + user.lastName;
}

//const element = <h2>Čau, {formatName(user)}!</h2>;

//ReactDOM.render(element, document.getElementById('root'));

function App() {
  return (
    <h2>Čau, {formatName(user)}!</h2>
  );
}

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/

export default App;
