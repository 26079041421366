import React, { useState, useEffect} from "react";
import "./Customer.css";

const url = "https://devvoj.plasanci.cz/API/CUST_TEST.php?ID=1";

function Customers() {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getGitHubUserWithFetch();
  }, []);

  const getGitHubUserWithFetch = async () => {
    const response = await fetch(url);
    const jsonData = await response.json();
    setUserData(jsonData);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h2>Customer ID=1 detail</h2>
      </header>
      <div className="user-container">
        <h5 className="info-item">CUSTOMER_ID: {userData.CUSTOMER_ID}</h5>
        <h5 className="info-item">NAME: {userData.NAME}</h5>
        <h5 className="info-item">STREET: {userData.STREET}</h5>
        <h5 className="info-item">CITY: {userData.CITY}</h5>
        <h5 className="info-item">PHONE: {userData.PHONE}</h5>
        <h5 className="info-item">CUSTOMER_TYPE_ID: {userData.CUSTOMER_TYPE_ID}</h5>
        <h5 className="info-item">CREATED_AT: {userData.CREATED_AT}</h5>
        <h5 className="info-item">CREATED_BY: {userData.CREATED_BY}</h5>
        <h5 className="info-item">LAST_EDITED_AT: {userData.LAST_EDITED_AT}</h5>
        <h5 className="info-item">LASTEDITED_BY: {userData.LASTEDITED_BY}</h5>
        <h5 className="info-item">DELETED: {userData.DELETED}</h5>
        <h5 className="info-item">EMAIL: {userData.EMAIL}</h5>
        <h5 className="info-item">PASSWORD_HASH: {userData.PASSWORD_HASH}</h5>
        <h5 className="info-item">CREDIT: {userData.CREDIT}</h5>
      </div>
    </div>
  );
}

export default Customers;
  